import { CourseSection } from './../../../../modules/interfaces';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-videos-category',
  templateUrl: './course-videos-category.component.html',
  styleUrls: ['./course-videos-category.component.scss']
})
export class CourseVideosCategoryComponent {
  @Input() section: CourseSection | undefined;  
}
