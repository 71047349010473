import { InfoCardComponent } from './info-card/info-card.component';
import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/assets/services/user.service';
import { User } from 'src/modules/interfaces';

@Component({
  selector: 'app-main-card',
  templateUrl: './main-card.component.html',
  styleUrls: ['./main-card.component.scss']
})

export class MainCardComponent {
  @ViewChild('infoCard', { static: false }) infoCard!: InfoCardComponent;
  @HostListener('window:resize', ['$event'])
  @Input() profile!: User;

  innerWidth: number;
  showTargetComponent: boolean;
  currentOpenedItemUsername?: string;

  async badgeEvent(badgeName: string) {
    const badgesImage: { [key: string]: string } = {
      Studikörkort: "badge-studio",
      Drönarkörkort: "badge-drone",
      Tryckkörkort: "badge-print",
      "Calortrans (transferpress)": "badge-calortrans",
      "Glowforge (Laser)": "badge-glowforge",
      "Oki (skrivare)": "badge-oki",
      "Prusa (3D-skrivare)": "badge-prusa",
      "Ricoh DTG (skrivare)": "badge-ricoh-dtg",
      "Sawgrass (skrivare)": "badge-sawgrass",
      "Brother ScanNCut (plotter)": "badge-scanncut",
      "Secabo (kepspress)": "badge-secabo-keps",
      "Secabo (muggpress)": "badge-secabo-mugg",
      "UltiMaker (3D-skrivare)": "badge-ultimaker",
    };

    const badgeFileName = badgesImage[badgeName];
    if (badgeFileName) {
      this.infoCard.updateBadge(badgeFileName);
    }
  }

  constructor(public dialogservice: MatDialog, public userService: UserService) {
    this.innerWidth = window.innerWidth;
    this.showTargetComponent = false;
  }

  onResize() {
    this.innerWidth = window.innerWidth;
  }

  public handleOpened(profile: string): void {
    this.currentOpenedItemUsername = profile;
  }
}
