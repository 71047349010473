
<amplify-authenticator
  variation="modal"
  [hideSignUp]="true"
  >

  <ng-template amplifySlot="header">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <img
        class="amplify-image"
        alt="Trainstation logo"
        src="../../assets/logo/Trainstation White.svg"
      />
    </div>
  </ng-template>

  <ng-template
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut"
    >
    <div class="signedIn">
      <p>Någonting har gått fel, vänligen kontakta admin</p>
      <p>Inloggad som:</p>
      <p class="bold">{{user.attributes.email}}</p>
      <button class="amplify-button" (click)="signOut()">LOGGA UT</button>
    </div>
    
  </ng-template>
</amplify-authenticator>
