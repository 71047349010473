import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: string): string {
    const duration = new Date(value);
    const minutes = duration.getUTCMinutes();
    const seconds = duration.getUTCSeconds();

    return `${minutes}m ${seconds}s`;
  }

}
