
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { ValueWordsComponent } from './value-words/value-words.component';
import { VisitorProgressComponent } from './visitor-progress/visitor-progress.component';
import { VideoComponent } from './video/video.component';
import { VideoVisitorProgressComponent } from './video-visitor-progress/video-visitor-progress.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule} from '@angular/material/grid-list';
import { VideoNavigationComponent } from './video-navigation/video-navigation.component';
import {MatTabsModule} from '@angular/material/tabs';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { MatRippleModule } from '@angular/material/core';
import { SafePipePipe } from './safe-pipe.pipe';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import { HomeComponent } from './home/home.component';
import { WhyComponent } from './why/why.component';
import { ProfileComponent } from './profile/profile.component';
import { SearchComponent } from './profile/search/search.component';
import { MainCardComponent } from './profile/usercards/main-card/main-card.component';
import { EditorCardComponent } from './profile/usercards/main-card/editor-card/editor-card.component';
import { InfoCardComponent } from './profile/usercards/main-card/info-card/info-card.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { AuthComponent } from './auth/auth.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';
import { amplifyConfiguration } from 'src/assets/services/amplify-config';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import { NgOptimizedImage } from '@angular/common'; 
import { DialogQrComponent } from './profile/usercards/main-card/info-card/dialog-qr/dialog-qr.component'; 
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { UsercardsComponent } from './profile/usercards/usercards.component';
import { CoursesComponent } from './courses/courses.component';
import { CoursePageComponent } from './courses/course-page/course-page.component';
import { CourseVideosSearchComponent } from './courses/course-page/course-videos-search/course-videos-search.component';
import { CourseVideosCategoryComponent } from './courses/course-page/course-videos-category/course-videos-category.component';
import { CourseVideoComponent } from './courses/course-page/course-videos-category/course-video/course-video.component';
import { MatSliderModule } from '@angular/material/slider';
import { DialogChangepasswordComponent } from './profile/usercards/main-card/editor-card/dialog-changepassword/dialog-changepassword.component';
import { MatRadioModule } from '@angular/material/radio';

Amplify.configure(amplifyConfiguration);

@NgModule({
  declarations: [
    AppComponent,
    ValueWordsComponent,
    VisitorProgressComponent,
    VideoComponent,
    VideoVisitorProgressComponent,
    VideoNavigationComponent,
    SafePipePipe,
    SignInFormComponent,
    HomeComponent,
    WhyComponent,
    ProfileComponent,
    SearchComponent,
    MainCardComponent,
    EditorCardComponent,
    InfoCardComponent,
    AuthComponent,
    DialogQrComponent,
    UsercardsComponent,
    CoursesComponent,
    CoursePageComponent,
    CourseVideosSearchComponent,
    CourseVideosCategoryComponent,
    CourseVideoComponent,
    DialogChangepasswordComponent,
  ],
  imports: [
    AmplifyAuthenticatorModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatGridListModule,
    MatTabsModule,
    HammerModule,
    MatRippleModule,
    YouTubePlayerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatExpansionModule,
    MatRadioModule,
    BrowserModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSliderModule,
    NgOptimizedImage,
    HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}

 export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}