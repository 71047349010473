<h2 mat-dialog-title class="title">{{'profile.change-password.title' | translate}}</h2>
<mat-dialog-content>
  <div class="username-wrapper">
    <label>{{'profile.change-password.username' | translate}}</label>
    <h3>{{ username }}</h3>
  </div>
  <div class="password-wrapper">
      <label>{{'profile.change-password.newpassword' | translate}}
        <input class="password-input" type="password" name="password" [(ngModel)]="password"
        (ngModelChange)="handlePasswordChange($event, 'password')">
      </label>
      <label>{{'profile.change-password.confirmpassword' | translate}}
        <input class="password-input" type="password" name="confirmPassword" [(ngModel)]="confirmPassword"
        (ngModelChange)="handlePasswordChange($event, 'confirmPassword')">
      </label>
  </div>
  <div class="error-password-wrapper">
    <ng-container *ngIf="!passwordsEqual() && confirmPassword.length > 0; else infoMessage">
      <p class="ts-info">
        {{'profile.change-password.error-1' | translate}}
      </p>
    </ng-container>
    <ng-template #infoMessage>
      <ng-container *ngIf="password.length > 0 && password.length < 6;">
        <p class="ts-warning">
          {{'profile.change-password.error-2' | translate}}
        </p>
      </ng-container>
    </ng-template>
  </div>
  <div class="checkbox-wrapper" *ngFor="let checkbox of checkBoxes; let i = index">
    <label>
      <input type="checkbox" [checked]="checkbox.checked" (change)="toggleCheckbox(i)">
      <span>{{ checkbox.text }}</span>
    </label>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" class="save-button" (click)="savePassword()"
    [disabled]="password === '' && confirmPassword === '' || !passwordEqualLength() || !passwordsEqual() || password.length < 6 || !allChecked">
    {{'profile.change-password.updatepassword' | translate}}
  </button>
</mat-dialog-actions>
