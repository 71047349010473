export interface PolicyStatement {
  readonly Effect: 'Allow' | 'Deny'
  readonly Action: readonly string[]
  readonly Resource: readonly string[]
}
export type PolicyStatements = readonly PolicyStatement[]
export interface PolicyDocument {
  readonly Version: '2012-10-17'
  readonly Statement: PolicyStatements
}

export const validLocations = [
  'bus',
  'haninge',
  'karlskoga',
  'skultuna',
  'vardagsrummet',
  'vivalla',
];

export const groups = [
  "SuperAdmin",
  "SiteAdminAll",
  "SiteAdminHaninge",
  "SiteAdminKarlskoga",
  "SiteAdminSkultuna",
  "SiteAdminVivalla",
  "TrainerAll",
  "TrainerHaninge",
  "TrainerKarlskoga",
  "TrainerSkultuna",
  "TrainerVivalla"
]

export const locations = [
  "Haninge", 
  "Karlskoga", 
  "Skultuna", 
  "Vivalla"
]