<div *ngFor="let theme of listOfThemes; index as t">
    <div *ngFor="let color of listOfColors; index as c">
        <div *ngIf="t === routeIndex && c === routeIndex">
            <div class="container" [ngClass]="theme">
                <div *ngFor="let video of listOfVideos; index as i">
                    <div *ngIf="i === cardId">
                        <mat-card class="card green" [style.height]="viewHeight" (swiperight)="nextVideo()"
                            (swipeleft)="previousVideo()" [ngClass]="color">
                            <mat-checkbox color="primary" #myCheckbox
                                (click)="saveVideoStatus()">{{ 'video.klar' | translate}}</mat-checkbox>
                            <mat-card-header>
                                <h1>{{ video.title | translate}}</h1>
                            </mat-card-header>
                            <mat-card-content class="content">
                                <youtube-player [width]="viewWidth" [height]="(viewWidth*.6)" #player
                                    [videoId]="video.url" (stateChange)="onStateChange($event)"
                                    (ready)="onPlayerReady($event)"
                                    [playerVars]="{rel: 0}"></youtube-player>

                            </mat-card-content>
                            <!-- <mat-card-title class="description"> {{video.description}} </mat-card-title> -->
                            <mat-card-title class="description"> {{ video.description | translate}}</mat-card-title>
                          </mat-card>
                          <div class="nav-footer">
                              <button color="primary" mat-icon-button (click)="previousVideo()"><i
                                      class="arrow left"></i></button>
                                      <span class="nav-text">{{video.navtext | translate}}</span>
                              <button color="primary" [style.visibility]="isVisible ? 'visible' : 'hidden'" mat-icon-button (click)="nextVideo()"><i
                                      class="arrow right"></i></button>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
