import { UserService } from 'src/assets/services/user.service';
import { ToolbarHeightService } from './toolbar-height.service';
import { Component, AfterViewInit, HostListener } from '@angular/core';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { slideInAnimation } from './animations';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { translations } from '@aws-amplify/ui-angular';
import { I18n } from 'aws-amplify';
import { AuthService } from './auth/auth.service';
import { StyleManager } from '../assets/services/style-manager.service';

I18n.putVocabularies(translations);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]

})
export class AppComponent implements AfterViewInit{
  title = 'trainer-app';
  name = 'trainer-app';
  toolbar: any;
  toolbarHeight: any;
  switchLanguageButton = 'Svenska';
  promptMsg = "Ny version tillgänglig. ";
  currentApplicationVersion = environment.appVersion;
  isLoggedIn$ = this.authService.isLoggedIn$;
  deviceWidth = window.innerWidth;
  menuOpen = false;
  isDark = this.styleManager.isDark;


  constructor(private router: Router, private toolbarHeightService: ToolbarHeightService, private contexts: ChildrenOutletContexts,
    private translate: TranslateService, private swUpdate: SwUpdate, private snackbar: MatSnackBar, private authService: AuthService, private styleManager: StyleManager, public userService : UserService){

    translate.setDefaultLang('sv');
    translate.use('sv');
    I18n.setLanguage('sv');
  }

  toggleDarkTheme() {
    this.styleManager.toggleDarkTheme();
    this.isDark = !this.isDark;
  }

  ngOnInit(){
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        console.log('Update Available: ', evt);
        const snack = this.snackbar.open(this.promptMsg, 'Uppdatera', {duration: undefined});
        snack
          .onAction()
          .subscribe(() => {
            window.location.reload();
          });
      });
  }

  ngAfterViewInit(): void {
    this.toolbar = document.querySelector("mat-toolbar");
    this.toolbarHeight = this.toolbar ? this.toolbar.clientHeight : 64;
    this.toolbarHeightService.setToolbarHeight(this.toolbarHeight);

    this.translate.get('app.update-prompt').subscribe((res: string) => {
      this.promptMsg = res;
    })
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  selectLanguage(){
    if(this.translate.currentLang === 'sv'){
      this.translate.use('en');
      this.switchLanguageButton = 'English'
      I18n.setLanguage('en');
    } else {
      this.translate.use('sv');
      this.switchLanguageButton = 'Svenska'
      I18n.setLanguage('sv');
    }
  }

  menuOpened(){
    this.menuOpen = !this.menuOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.deviceWidth = window.innerWidth;
  }

  async signOut() {
    await this.authService.signOut(); 
    this.router.navigate(['our-dream']);
  }
}

