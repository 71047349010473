<!-- <div class="ts-user-card-container"> -->
    <mat-card class="ts-user-card">
        <mat-card-content class="ts-user-card-content">
            <!-- <app-info-card [profileData]="profile" [badges]="getBadgesForUser(profile.username)"></app-info-card> -->
            <app-info-card #infoCard [profileData]="profile"></app-info-card>
            <!-- provides with a expansion of the mat-card -->
                <mat-expansion-panel class="ts-user-expantion-panel">
                    <mat-expansion-panel-header>
                        {{'profile.main-card.edit' | translate}}
                    </mat-expansion-panel-header>
                    <!-- opens the editor for a profile in a editor component -->
                    <app-editor-card [myData]="profile" (badgeEvent)="badgeEvent($event)"></app-editor-card>

                </mat-expansion-panel>
        </mat-card-content>
    </mat-card>
<!-- </div> -->
