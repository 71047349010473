import { CourseVideo, Statistics } from './../../../../../modules/interfaces';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-video',
  templateUrl: './course-video.component.html',
  styleUrls: ['./course-video.component.scss']
})
export class CourseVideoComponent implements OnInit {
  @Input() video!: CourseVideo;
  showVideo = false;
  views!: number;
  likes!: number;
  comments!: number;

  ngOnInit() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    const videoStats = this.video.statistics;
    this.videoStatistics(videoStats);
  }

  videoStatistics(videoStats: Statistics) {
      this.views = videoStats.views;
      this.likes = videoStats.likes;
      this.comments = videoStats.comments;
  }
}
