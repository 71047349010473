<h3 class="ts-header">{{ "courses.categories-header" | translate }}</h3>
<mat-card class="ts-user-card">
  <mat-card-content class="ts-user-card-content">
    <div class="loader-videos" *ngIf="this.isDone == false"></div>
    <ng-container *ngFor="let category of categories" id="testing">
      <div class="ts-user-card-container" *ngIf="category.status == 'public'">
        <div class="ts-card-video" (click)="navigateToCategory(category.id, category.name)">
          <div class="ts-card-header">
            <p>{{ category.name }}</p>
            <small>{{ category.description }}</small>
          </div>
          <img src="{{ category.imageUrl }}" alt="{{ category.name }}" title="{{ category.name }}" />
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>

<div *ngIf="!scrollPostionIsTop">
  <button matTooltip="Gå till toppen av sidan" [matTooltipPosition]="'left'" class="ts-scroll-to-top" mat-mini-fab
    color="primary" (click)="onScrollToTop()">
    <div class="ts-scroll-icon"><mat-icon>arrow_upward</mat-icon></div>
  </button>
</div>