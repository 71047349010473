import { ToolbarHeightService } from './../toolbar-height.service';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-value-words',
  templateUrl: './value-words.component.html',
  styleUrls: ['./value-words.component.scss']
})
export class ValueWordsComponent implements OnInit{
  category: string;
  viewHeight: string;
  currentApplicationVersion = environment.appVersion

  constructor(private router: Router, private toolbarHeightService: ToolbarHeightService){
    this.category = "";
    this.viewHeight = "";
    
  }

  ngOnInit(): void {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";
  }

  navigateToVideos(){
    this.router.navigate(['/videonav']);
  }

  navigateToItem(category: string){ 
    this.category = category;
    this.router.navigate(['/video', category, 0]);
  }

  //For testing swipe functionality
  swipe() {
    console.log('Swiped!');
  }

  //dynamically check the screensize to fix the buttons so there is no trouble for any device
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";

  }

}
