<div class="flex" [style.height]="viewHeight">
  <div class="flex box green-theme">
    <button
      class="box"
      mat-raised-button
      color="primary"
      (click)="navigateToItem('newcomer')"
      (swiperight)="navigateToItem('newcomer')"
    >
      <div class="speech-bubble">
        <h2>{{'visitor-progress.newcomer.speech-bubble' | translate}}</h2>
      </div>
      <div class="subheading" [innerHTML]="'visitor-progress.newcomer.subheading' | translate"></div>
    </button>
  </div>
  <div class="flex box red-theme">
    <button
      class="box"
      mat-raised-button
      color="primary"
      (click)="navigateToItem('recurring')"
      (swiperight)="navigateToItem('recurring')"
    >
      <div class="speech-bubble">
        <h2>{{'visitor-progress.recurring.speech-bubble'| translate}}</h2>
      </div>
      <div class="subheading" [innerHTML]="'visitor-progress.recurring.subheading'| translate"></div>
    </button>
  </div>
  <div class="flex box orange-theme">
    <button
      class="box"
      mat-raised-button
      color="primary"
      (click)="navigateToItem('regular')"
      (swiperight)="navigateToItem('regular')"
      >
      <div class="speech-bubble">
        <h2>{{'visitor-progress.regular.speech-bubble' | translate}}</h2>
      </div>
      <div class="subheading" [innerHTML]="'visitor-progress.regular.subheading' | translate"></div>
    </button>
  </div>
</div>
