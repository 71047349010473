<mat-card-content class="ts-youtube">
    <div class="ts-youtube-center">
        <div class="ts-video-img"  (click)="showVideo = true" *ngIf="showVideo == false" >
            <span class="ts-video-stats"><b>Views: </b>{{views}} <b>Likes: </b>{{likes}} <b>Comments: </b>{{comments}}</span>
            <img src="https://img.youtube.com/vi/{{video.youtubeId}}/mqdefault.jpg" alt="video" onerror="this.src=defaultimageURL"
                class="ts-video-thumbnail" >
        </div>
        <youtube-player *ngIf="showVideo == true" [videoId]="video.youtubeId" [width]="250" [height]="180"
            [playerVars]="{ modestbranding: 1, showinfo: 0 }"> </youtube-player>
    </div>

    <div class="ts-video-details">
        <span class="ts-video-level">{{video.level}}</span>
        <div class="ts-video-title">{{video.title}}{{video.titleCustom}}</div>
        <p class="ts-video-description">{{video.description}}{{video.descriptionCustom}}</p>
    </div>
</mat-card-content>