<div class="container blue-theme" [style.height]="viewHeight">

    <app-auth></app-auth>
    <!-- <h1>
        {{'sign-in-form.h1' | translate}}
    </h1>
    <mat-form-field appearance="fill">
        <mat-label>{{'sign-in-form.email' | translate}}</mat-label>
        <input matInput placeholder="{{'sign-in-form.email-placeholder' | translate}}" [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{'sign-in-form.password' | translate}}</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    <button mat-raised-button routerLink="value" color="primary">
        {{'sign-in-form.login-button' | translate}}
    </button>
    <span class="heighten"></span> -->
</div>