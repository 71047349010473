import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/assets/services/user.service';

interface CheckboxItem {
  text: string;
  checked: boolean;
}

@Component({
  selector: 'app-dialog-changepassword',
  templateUrl: './dialog-changepassword.component.html',
  styleUrls: ['./dialog-changepassword.component.scss']
})

export class DialogChangepasswordComponent {
  username = ''
  password = ''
  confirmPassword = ''
  allChecked = false
  checkBoxes: CheckboxItem[] = [
    { text: this.translate.instant('profile.change-password.checkbox-1'), checked: false },
    { text: this.translate.instant('profile.change-password.checkbox-2'), checked: false },
    { text: this.translate.instant('profile.change-password.checkbox-3'), checked: false },
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService, public snackBar: MatSnackBar, public dialog: MatDialogRef<DialogChangepasswordComponent>, private translate: TranslateService) {
    this.username = data.username
  }

  toggleCheckbox(i: number) {
    this.checkBoxes[i].checked = !this.checkBoxes[i].checked;
    this.allChecked = this.checkBoxes.every((checkbox) => checkbox.checked);
  }

  passwordEqualLength(): boolean {
    return this.password.length === this.confirmPassword.length;
  }

  passwordsEqual(): boolean {
    return this.password === this.confirmPassword;
  }

  handlePasswordChange(event: any, inputName: 'password' | 'confirmPassword') {
    this[inputName] = event;
  }

  async savePassword() {
    try {
      await this.userService.changePassword(this.username, this.password);
      this.dialog.close();
      this.snackBar.open(this.translate.instant('profile.change-password.passwordchanged'), '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    } catch (error) {
      this.dialog.close();
      this.snackBar.open(error as string, '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }
    
  }
}
