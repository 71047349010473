import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from 'src/assets/services/user.service';
import { AuthService } from './auth.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  authSubscription!: Subscription;
  users: any[] = [];
  users$ = new BehaviorSubject<any[]>([]);
  groups!: string[] | null;

  constructor(private router: Router, public auth: AuthService, public userService: UserService, public authenticator: AuthenticatorService) { }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  ngOnInit() {
    this.authSubscription = this.auth.auth$.subscribe(v => {
      this.groups = v.groups
      if (this.auth.isLoggedIn$) {
        this.goToHome();
      }
    });
  }

  public formField = {
    signIn: {
      username: {
        placeholder: 'ange användarnamn',
        isRequired: true,
        label: 'Användarnamn'
      },
      password: {
        placeholder: 'ange lösenord',
        isRequired: true,
        label: 'Lösenord'
      }
    }
  };

  goToHome() {
    this.router.navigate(['our-dream']);
  }
}
