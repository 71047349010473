import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';
import { API, Auth } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { AdminUser, Badge, User } from 'src/modules/interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  watchedVideos = 0;
  specificCount = 0;

  constructor(private loadingService: LoadingService) { }

  async getUsers() {
    const uri = `/v1/users`;
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    })).users as AdminUser[];

    return res;
  }
  
  async getUser(userName: string) {
    const uri = `/v1/users/${userName}`;
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })).user as User;

    return res;
  }

  async getCheckedInUsers(location: string, policy: string, today: string) {
    const uri = `/v1/users?checkedInTo=${location}&locations=${policy}&lastActiveAfter=${today}` // location indicated what location the admin is searching users in.
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })).users as AdminUser[];

    return res;
  }

  async getBadges() {
    let badges;

    const baseUrl = environment.endpoint2;
    await fetch(baseUrl + `/badges/get/`, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }),
    })
      .then((response: Response) => response.json())
      .then((response) => {
        badges = response;
      })
      .catch((error) => {
        console.log(error)
      });

    return badges as unknown as Badge[];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async updateBadges(badge: any, username: string) {
    if (!badge.users.includes(username)) {
      badge.users.push(username);
    } else {
      badge.users.splice(badge.users.indexOf(username), 1);
    }

    const baseUrl = environment.endpoint2;
    await fetch(baseUrl + `/badges/patch/`, {
      mode: "cors",
      method: "PATCH",
      headers: new Headers({
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }),
      body: JSON.stringify({
        id: badge.id,
        users: badge.users,
      }),

    })
      .then((response: Response) => response.json())
      .catch((error) => {
        console.log(error);
      });
  }

  async watch(site: string) {
    const uri = `/v1/watch/checkin#${site}`;
    const encoded = encodeURIComponent(uri)
    await API.put('backend', encoded, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    });
  }

  async unWatch(site: string) {
    const uri = `/v1/watch/checkin#${site}`;
    const encoded = encodeURIComponent(uri)
    await API.del('backend', encoded, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    });
  }

  async removeSubscription(id: string) {
    try {
      this.loadingService.add()
      const uri = `/v1/subscriptions/${id}`;
      await API.del('backend', uri, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
      });

      this.setNotifications(null);
    } catch (error) {
      throw new Error('Error removing subscription.');
    } finally {
      this.loadingService.done()
    }
  }

  async addSubscription(sub: PushSubscription) {
    this.loadingService.add()
    const uri = `/v1/subscriptions`;
    const res = (await API.post('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: sub
    })).id;
    this.setNotifications(res)
  }

  private $notificationId = new ReplaySubject<string | null>();
  public notificationId$ = this.$notificationId.asObservable();
  private notificationKey = 'notifications';

  public checkNotifications() {
    const item = localStorage.getItem(this.notificationKey);
    this.$notificationId.next(item);
    return item;
  } 

  public setNotifications(setVal: string | null) {
    if (!setVal)
      localStorage.removeItem(this.notificationKey);
    else
      localStorage.setItem(this.notificationKey, setVal);
    return this.checkNotifications();
  }

  async patchUser(userName: string, userData: User) {
    const uri = `/v1/users/${userName}`;
    const res = (await API.patch('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        email: userData.email,
        magnets: userData.magnets,
        notes: userData.notes,
        attributes: userData.attributes,
        groups: userData.groups
      }
    }));
    
    return res;
  }

  async getUsersVideosWatchCount(userName: string): Promise<number> {
    const baseUrl = environment.endpoint2;
    const url = `/getwatchedvideos/?uid=${userName}&count=true`;
    await fetch(baseUrl + url, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }),
    })
      .then((response: Response) => response.json())
      .then((response) => {
        this.watchedVideos = response.Count
      })
      .catch(() => {
        this.watchedVideos = 0
      });
    return this.watchedVideos
  }

  async getUsersUploadCount(userName: string): Promise<number> {
    const baseUrl = environment.endpoint;
    const url = `/v1/files/${encodeURIComponent(userName)}`;
    await fetch(baseUrl + url, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }),
    })
      .then((response: Response) => response.json())
      .then((response) => {
        const usersCount = response.files
        this.specificCount  = usersCount.length
      })
      .catch(() => {
        this.specificCount = 0
      });
    return this.specificCount
  }

  async getUserCheckInCount(userName: string) {
    const uri = `/v1/openevents/${userName}?event=checkin`;
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }));
    return res
  }

  async getFreeItems() {
    try {
      const baseUrl = environment.endpoint2;
      const response = await fetch(baseUrl + `/freeitems/get/`, {
        mode: "cors",
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }),
      })
        .then((response: Response) => response.json());
      return response

    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async addUserFreeItem(responseFreeItemId: string, responseFreeItemUsers: string[]) {
    try {
      const baseUrl = environment.endpoint2;
      await fetch(baseUrl + `/freeitems/patch/`, {
        mode: "cors",
        method: "PATCH",
        headers: new Headers({
          Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }),
        body: JSON.stringify({
          id: responseFreeItemId,
          users: responseFreeItemUsers,
        }),
      })
    }
    catch (error) {
      console.log(error);
    }
  }

  async changePassword(username: string, password: string) {
    try {
      const response = await fetch(`${environment.endpoint}/v1/users/${username}/password`, {
        mode: "cors",
        method: "POST",
        headers: new Headers({
          Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }),
        body: JSON.stringify({password: password})
      });
  
      if (response.ok) {
        return response;
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  }
}