<div class="ts-profile-component container">
    <h2 *ngIf="this.locations.length !== 1">{{'profile.h2' | translate}} ({{userList.length}})</h2> 
    <h2 *ngIf="this.locations.length === 1"> {{'profile.h2' | translate}}: {{location.charAt(0).toUpperCase() + location.slice(1)}} ({{userList.length}})</h2> 
    <div class="ts-search-container">
      <app-search 
    (toProfile)="fromSearch($event)" 
    (isProfileReady)="checkIfDone($event)" 
    [fromProfile]="location" 
    #searchComponent>
  </app-search>
        <mat-form-field  *ngIf="this.locations.length !== 1" class="ts-profile-dropdown" appearance="fill">
            <mat-label>Välj den platsen du befinner dig på: </mat-label>
            <mat-select [(ngModel)]="location" (selectionChange)="onLocationChange($event.value)">
              <mat-option *ngFor="let location of locations" [value]="location.value"> {{location.text}} </mat-option>
            </mat-select>
        </mat-form-field>
         <div class="notification-area" *ngIf="location">
        <button mat-icon-button (click)="reloadLocation()" class="margin-right">
          <div class="button-wrapper">
            <div class="button-content">
              <mat-icon>refresh</mat-icon>
              <div class="notification-text">Uppdatera</div>
            </div>
          </div>
        </button>
        <button mat-icon-button (click)="toggleNotifications()"
          [title]="(userService.notificationId$ | async) ? 'Ta bort prenumeration' : 'Prenumerera på meddelanden'">
          <div class="button-wrapper">
            <div class="button-content">
              <mat-icon>{{ (userService.notificationId$ | async) ? 'notifications_off' : 'notifications_on' }}</mat-icon>
              <div class="notification-text">{{ (userService.notificationId$ | async) ? 'Slå av notiser' : 'Slå på notiser' }}
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="loader-users" *ngIf="this.isDone == false"></div>
    <app-usercards [fromParent]="userList" *ngIf="this.isDone == true"></app-usercards>
</div>
<div *ngIf="!scrollPostionIsTop">
    <button matTooltip="Gå till toppen av sidan" [matTooltipPosition]="'left'" class="ts-scroll-to-top" mat-mini-fab
        color="primary" (click)="onScrollToTop()">
        <div class="ts-scroll-icon"><mat-icon>arrow_upward</mat-icon></div>
    </button>
</div> 