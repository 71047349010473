<!-- <p>1.1 Här finns sökkomponenten och komponenter som håller videokomponenter</p> -->
<div class="ts-course-name" *ngIf="this.isDone == true">{{category}}</div>
<app-course-videos-search [videosFromCoursePage]="allVideosInCategory" (toCoursePage)="fromSearch($event)"></app-course-videos-search>
<div class="loader-videos-center">
    <div class="loader-videos" *ngIf="this.isDone == false"></div>
</div>
<div *ngFor="let section of sections;">
    <div class="ts-section-name">{{section?.name}}</div>
    <app-course-videos-category [section]=section class="ts-container-scroll-x"></app-course-videos-category>
</div>

<div *ngIf="!scrollPostionIsTop">
    <button matTooltip="Gå till toppen av sidan" [matTooltipPosition]="'left'" class="ts-scroll-to-top" mat-mini-fab
        color="primary" (click)="onScrollToTop()">
        <div class="ts-scroll-icon"><mat-icon>arrow_upward</mat-icon></div>
    </button>
</div> 