import { CoursesComponent } from './courses/courses.component';
import { ProfileComponent } from './profile/profile.component';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { HomeComponent } from './home/home.component';
import { WhyComponent } from './why/why.component';
import { VideoComponent } from './video/video.component';
import { VideoVisitorProgressComponent } from './video-visitor-progress/video-visitor-progress.component';
import { ValueWordsComponent } from './value-words/value-words.component';
import { VisitorProgressComponent } from './visitor-progress/visitor-progress.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth.guard';
import { CoursePageComponent } from './courses/course-page/course-page.component';

const routes: Routes = [
  {
    path: '',
    component: SignInFormComponent,
  },
  {
    path: 'our-dream',
    component: HomeComponent,
    data: { animation: 'ValueWordsPage' },
    canActivate: [AuthGuard],
  },
  {
    path: 'our-approach',
    component: WhyComponent,
    data: { animation: 'ValueWordsPage' },
    canActivate: [AuthGuard],
  },
  {
    path: 'intro',
    component: ValueWordsComponent,
    data: { animation: 'ValueWordsPage' },
    canActivate: [AuthGuard],
  },
  {
    path: 'our-reception',
    component: VisitorProgressComponent,
    data: { animation: 'ValueWordsPage' },
    canActivate: [AuthGuard],
  },
  {
    path: 'video-visitor-progress',
    component: VideoVisitorProgressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'video-visitor-progress/:category/:id',
    component: VideoVisitorProgressComponent,
    data: { animation: 'VideoPage' },
    canActivate: [AuthGuard],
  },
  {
    path: 'video',
    component: VideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'video/:category/:id',
    component: VideoComponent,
    data: { animation: 'VideoPage' },
    canActivate: [AuthGuard],
  },
  {
    path: 'our-visitors',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: AuthComponent,
  },
  {
    path: 'courses',
    component: CoursesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'courses/:id/:category',
    component: CoursePageComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
