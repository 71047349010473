<div *ngIf="isLoggedIn$ | async">

  <mat-toolbar class="flex-toolbar">
    <div class="logo" routerLink="our-dream">
      <img src="assets/logo/Trainstation White.svg" class="header-text" />
    </div>
    <div class="toolbar-right">
      <ng-container *ngIf="deviceWidth > 860; else burger_menu">
        <span class="nav-modules">
          <span class="module-why" *ngIf="isLoggedIn$ | async">
            <button mat-button routerLink="our-dream">
              <span>{{'app.nav-1' | translate}}</span>
            </button>
          </span>
          <span class="module-why" *ngIf="isLoggedIn$ | async">
            <button mat-button routerLink="our-approach">
              <span>{{'app.nav-2' | translate}}</span>
            </button>
          </span>
          <span class="module-intro-trainer" *ngIf="isLoggedIn$ | async">
            <button mat-button routerLink="our-reception">
              <span>{{'app.nav-3' | translate}}</span>
            </button>
          </span>
          <span class="module-visitors" *ngIf="isLoggedIn$ | async">
            <button mat-button routerLink="our-visitors">
              <span>{{'app.nav-4' | translate}}</span>
            </button>
          </span>
          <span *ngIf="isLoggedIn$ | async">
            <button mat-button routerLink="courses">
              <span>{{'app.nav-5' | translate}}</span>
            </button>
          </span>
        </span>
        <span class="nav-buttons">
          <button
            mat-button
            (click)="selectLanguage()"
            class="btn-selection"
            aria-label="Välj språk"
          >
            <mat-icon class="language" >language</mat-icon>
          </button>
        </span>
        <span class="nav-login">
          <button
            mat-button
            class="login"
            (click)="signOut()"
            aria-label="Logga in"
          >
            <ng-container *ngIf="isLoggedIn$ | async; else other_content">
              <mat-icon>logout</mat-icon>
            </ng-container>
          </button>
        </span>
        </ng-container>
    </div>
  </mat-toolbar>
  <ng-template #other_content>
    <mat-icon>login</mat-icon>
    <span class="btn-label">{{'app.login-button' | translate}}</span>
  </ng-template>

  <ng-template #burger_menu>
    <button mat-icon-button class="burgermenu" [mat-menu-trigger-for]="menu" [class.animate]="menuOpen"
      (click)="menuOpened()">
      <mat-icon class="burger-icon" [ngClass]="{'animate': menuOpen}">menu</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <div *ngIf="isLoggedIn$ | async; else other_menu_items">
        <div class="ts-dropdown">
          <button mat-button routerLink="our-dream">
            <span>{{'app.nav-1' | translate}}</span>
          </button>
          <button mat-button routerLink="our-approach">
            <span>{{'app.nav-2' | translate}}</span>
          </button>
          <button mat-button routerLink="our-reception">
            <span>{{'app.nav-3' | translate}}</span>
          </button>
          <button mat-button routerLink="our-visitors">
            <span>{{'app.nav-4' | translate}}</span>
          </button>
          <button mat-button routerLink="courses">
            <span>{{'app.nav-5' | translate}}</span>
          </button>
          <span class="nav-buttons">
            <button
              mat-button
              (click)="selectLanguage()"
              class="btn-selection"
              aria-label="Välj språk"
            >
              <mat-icon class="language">language</mat-icon>
              <span class="btn-label">{{switchLanguageButton}}</span>
            </button>
            <button
              mat-button
              (click)="signOut()"
              class="login"

              aria-label="Logga in"
            >
              <ng-container *ngIf="isLoggedIn$ | async; else other_content">
                <span class="login-flex">
                  <mat-icon>logout</mat-icon>
                  <span class="btn-label"> {{'app.logout-button' | translate}}</span>
                </span>
              </ng-container>
            </button>
          </span>
        </div>
      </div>
    </mat-menu>
  </ng-template>

  <ng-template #other_menu_items>
    <button mat-menu-item routerLink="/">
      <span>{{'app.login-button' | translate}}</span>
    </button>
  </ng-template>
</div>

<span class="version-number"> v.{{currentApplicationVersion}}</span>

<div [@routeAnimations]="getRouteAnimationData()">
  <router-outlet></router-outlet>
</div>
