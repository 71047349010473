export function permissionValidator(groups: string[], allowedRoles: string[]): boolean {
  return groups.some(userGroup =>
    allowedRoles.some(allowedRole =>
      userGroup.toLowerCase() === allowedRole.toLowerCase() ||
      (allowedRole.toLowerCase() === "trainer" && userGroup.toLowerCase().startsWith("trainer")) ||
      (allowedRole.toLowerCase() === "siteadmin" && userGroup.toLowerCase().startsWith("siteadmin"))
    )
  );
}

export function groupValidator(groups: string[], allowedRoles: string[]): boolean {
  return groups.some(userGroup =>
    allowedRoles.some(allowedRole =>
      userGroup.toLowerCase() === allowedRole.toLowerCase() 
    )
  );
}