import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ToolbarHeightService {
  
  private toolbarHeight: number;

  constructor(){
    this.toolbarHeight = 0;
  }

  setToolbarHeight(height: number): void {
    this.toolbarHeight = height;
  }

  getToolbarHeight(): number {
    return this.toolbarHeight;
  }
}
