import { ActivatedRoute } from '@angular/router';
import { Component, HostListener } from '@angular/core';
import { CourseService } from 'src/assets/services/course.service';
import { CourseSection, CourseVideo, Statistics } from 'src/modules/interfaces';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-course-page',
  templateUrl: './course-page.component.html',
  styleUrls: ['./course-page.component.scss']
})
export class CoursePageComponent {
  category = "";
  categoryId = "";
  sections: CourseSection[] = [];
  allVideosInCategory: CourseVideo[] = [];
  isDone = false;
  scrollPostionIsTop = true;

  constructor(private courseService: CourseService, private route: ActivatedRoute, private readonly viewport: ViewportScroller) {
    this.route.params.subscribe((params) => {
      this.category = params['category'];
      this.categoryId = params['id'];
    });
    this.loadVideos();
  }

  async loadVideos() {
    let videos = await this.courseService.getVideos(this.categoryId);
    videos = this.videoStatisticsToObject(videos);
    this.allVideosInCategory = this.filterEmptyVideos(videos);
    this.sortVideosBySection(this.allVideosInCategory);
    this.isDone = true;
  }

  videoStatisticsToObject(videos: CourseVideo[]) {
    videos.forEach(video => {

      const statistics: Statistics = {
        views: 0,
        likes: 0,
        comments: 0
      };

      const videoStats = video.statistics;

      if (typeof videoStats === 'string') {
        const data = JSON.parse(videoStats);
        statistics.views = parseInt(data.viewCount);
        statistics.likes = parseInt(data.likeCount);
        statistics.comments = parseInt(data.commentCount);
      }
      video.statistics = statistics;
    })

    return videos;
  }

  filterEmptyVideos(videos: CourseVideo[]) {
    return videos.filter((video) => {
      return (video.youtubeId !== null && video.youtubeId !== "undefined" && video.youtubeId !== "")
    });
  }

  fromSearch(videos: CourseVideo[]) {
    this.sortVideosBySection(videos);
  }

  sortVideosBySection(videos: CourseVideo[]) {
    this.sections = [];
    videos.forEach((video) => {
      const section = this.sections.find(section => {
        return section.id === video.section_id;
      })
      if (!section) {
        this.sections.push({ id: video.section_id, name: video.groupDescription, videos: [video] })
      } else {
        this.sections[this.sections.indexOf(section)].videos.push(video);
      }
    });
  }

  checkIfDone(value: boolean) {
    this.isDone = value;
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (scrollY === 0) {
      this.scrollPostionIsTop = true;
    } else {
      this.scrollPostionIsTop = false;
    }
  }
}
