import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/assets/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Badge, User } from 'src/modules/interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import magnetList from '../../../../../assets/user-items/magnets.json';
import { MatDialog } from '@angular/material/dialog';
import { DialogChangepasswordComponent } from './dialog-changepassword/dialog-changepassword.component';
import { groupValidator, permissionValidator } from 'src/assets/services/permissionValidator';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalService } from 'src/app/local.service';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import PBAC from 'pbac'
// import { PolicyDocument, validLocations } from 'common';


@Component({
  selector: 'app-editor-card',
  templateUrl: './editor-card.component.html',
  styleUrls: ['./editor-card.component.scss'],
})
export class EditorCardComponent implements OnInit {
  showZipcodeForm = false;
  showEmailForm = false;
  nickName = "";
  email = "";
  birthdate = new Date('2000-01-01');
  zipcode = 0;
  notes = "";
  freeItem = false;
  freeItemName = "";
  allFreeItems: any[] = [];
  loadedBadges = false;
  badgeTranslation = "profile.editor-card.badges."
  badges: Badge[] = [];
  myBadges: Badge[] = [];
  userForm!: FormGroup;
  user: User | undefined
  locations = ["Haninge", "Karlskoga", "Skultuna", "Vivalla"];
  groups = ["SuperAdmin", "SiteAdminAll", "SiteAdminHaninge", "SiteAdminKarlskoga", "SiteAdminSkultuna", "SiteAdminVivalla",
    "TrainerAll", "TrainerHaninge", "TrainerKarlskoga", "TrainerSkultuna", "TrainerVivalla"]
  homelocation = "";
  selectedGroups: string[] = [];
  loggedInUserGroups: string[] = [];
  // loggedInUserGroups2 = '';
  // policydocument?: PolicyDocument;
  // loggedInUser: any = ''

  @Output() badgeEvent = new EventEmitter<any>();

  @Input() myData: User = {
    username: "",
    email: "",
    attributes: {
      nickname: "",
      gender: "",
      "custom:birthdate": new Date(2000 - 20 - 20),
      "custom:zipcode": 99999,
      homelocation: ""
    },
    groups: [],
    notes: "",
    badges: [],
    specialBadges: [],
    magnets: []
  };

  allMagnets: { magnetName: string, displayText: string, owned: boolean }[] = [];

  constructor( public localService: LocalService, public userService: UserService, public snackBar: MatSnackBar, private translate: TranslateService, private fb: FormBuilder, public dialog: MatDialog, public auth: AuthService) {

  }

  async ngOnInit(): Promise<void> {
    this.nickName = this.myData.attributes.nickname;
    this.email = this.myData.email;
    this.zipcode = this.myData.attributes["custom:zipcode"];
    this.birthdate = this.myData.attributes["custom:birthdate"];
    this.findFreeItems();
    this.findMagnets();
    this.loggedInUserGroups = this.auth.getGroups()
    this.userForm = this.fb.group({
      nickname: [this.myData.attributes.nickname, Validators.required],
      birthdate: ['', [Validators.required, this.dateFormatValidator]]
    });

    const user = (await this.getUser());
    this.user = user
    this.notes = user.notes;
    this.selectedGroups = user.groups;
    this.homelocation = user.attributes.homelocation
    this.loadBadges();
    if (user.magnets) {
      this.allMagnets.forEach((magnet) => {
        user.magnets.forEach((myMagnet: { toString: () => string; }) => {
          if (magnet.magnetName === myMagnet.toString()) {
            magnet.owned = true;
          }
        })
      })
    }
  }
  

  // getLocations = (policydocument: PolicyDocument) => {

  //   const pbac = new PBAC(policydocument)
  //   return pbac.evaluate({ action: 'user:ListUsers', resource: 'trn:user:*' })
  //     ? '*'
  //     : validLocations.filter((location) => pbac.evaluate({ action: 'user:ListUsers', resource: `trn:user:${location}` })).join(',')
  // }

  radioChangeLocation(location: string): void {
    this.homelocation = location.toLocaleLowerCase()
  }

  checkboxChangeGroup(group: string) {
    this.selectedGroups = this.selectedGroups || [];

    const index = this.selectedGroups.indexOf(group);
    if (index === -1) {
      this.selectedGroups.push(group);
    } else {
      this.selectedGroups.splice(index, 1);
    }
  }

  isCheckedLocation(location: string): boolean {
    return location.toLocaleLowerCase() === this.homelocation;
  }

  isCheckedGroup(group: string): boolean {
    return this.selectedGroups ? this.selectedGroups.includes(group) : false;
  }

  isDisabled(groups: string): boolean {
    if (this.loggedInUserGroups) {
      const isSuperAdmin = groupValidator(this.loggedInUserGroups, ["SuperAdmin"]);
      const isSiteAdminAll = groupValidator(this.loggedInUserGroups, ["SiteAdminAll"]);
      const isSiteAdminLocation = permissionValidator(this.loggedInUserGroups, ["SiteAdmin"]);

      if (isSuperAdmin) {
        return false;
      }

      if (isSiteAdminAll && groups !== "SuperAdmin" && !groups.startsWith("SiteAdmin")) {
        return false;
      }

      if (isSiteAdminLocation && !groups.startsWith("Site")) {
        return this.sanitizeLocation(this.loggedInUserGroups, groups)
      }
    }
    return true
  }

  isDisabledRadio(selectedGroups: string[]) {
    const isAdmin = permissionValidator(this.loggedInUserGroups, ["SuperAdmin", "SiteAdmin"]);
    const isTrainer = permissionValidator(this.loggedInUserGroups, ["Trainer"]);

    if (isAdmin) {
      return false
    }
    if (isTrainer && selectedGroups?.length === 0) {
      return false
    }
    return true
  }

  sanitizeLocation(loggedInUserGroups: string[], group: string): boolean {
    const validLocations: string[] = []
    const pattern = /^SiteAdmin|^Trainer/
    const sanitizedGroup = group.replace(pattern, '').trim().toLowerCase();
    loggedInUserGroups.map(item => {
      const sanitized = item.replace(pattern, '').trim().toLowerCase();
      validLocations.push(sanitized)
    })
    if (validLocations.includes(sanitizedGroup)) {
      return false
    }
    return true
  }

  isAdmin(): boolean {
    const isAdmin = permissionValidator(this.loggedInUserGroups, ["SiteAdmin", "SuperAdmin"]);
    if (isAdmin) {
      return true;
    }
    return false
  }

  changePassword() {
    this.dialog.open(DialogChangepasswordComponent, {
      maxWidth: '475px',
      data: {
        username: this.myData.username
      }
    });
  }

  updateUser() {
    if (this.userForm.valid) {

      const myMagnets: string[] = [];

      this.allMagnets.forEach((magnet) => {
        if (magnet.owned) myMagnets.push(magnet.magnetName);
      })
      const isAdmin = permissionValidator(this.loggedInUserGroups, ["SuperAdmin", "SiteAdmin"]);
      const tempNewUserData: any = {
        username: this.myData.username,
        email: this.email,
        attributes: {
          nickname: this.nickName,
          gender: this.myData.attributes.gender,
          "custom:birthdate": this.birthdate,
          "custom:zipcode": this.zipcode,
          homelocation: this.homelocation
        },
        notes: this.notes,
        magnets: myMagnets,
        ...(isAdmin ? { groups: this.selectedGroups } : {}),
      };

      Object.keys(tempNewUserData).forEach(key => tempNewUserData[key] === undefined && delete tempNewUserData[key]);
      Object.keys(tempNewUserData.attributes).forEach(key => tempNewUserData.attributes[key] === undefined && delete tempNewUserData.attributes[key]);

      this.userService.patchUser(this.myData.username, tempNewUserData).then(() => {
        this.displayUpdateMessage();
      }).catch(error => this.snackBar.open(error, 'Stäng', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }))
    }
  }

  displayUpdateMessage() {
    let msg = "";
    this.translate.get('profile.editor-card.update-message').subscribe((res: string) => {
      msg = res;
    })
    this.snackBar.open(msg + this.myData.username, '', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  addZipcode() {
    this.showZipcodeForm = !this.showZipcodeForm;
  }

  addEmail() {
    this.showEmailForm = !this.showEmailForm;
  }

  async loadBadges() {
    await this.userService.getBadges().then(res => {
      res.map((item) => {
        this.badges.push(item);
      })
    })
    this.getBadgesForUser(this.myData.username);
  }

  async getBadgesForUser(username: string) {
    const usersBadges: Badge[] = [];
    this.badges.forEach((element) => {
      if (element.users.includes(username)) {
        usersBadges.push(element);
        if (!this.loadedBadges) {
          this.badgeEvent.emit(element.name);
        }
      }
    });
    this.loadedBadges = true;
    this.myBadges = usersBadges;
  }

  async updateBadge(badgeId: string, badgeName: string) {
    this.badgeEvent.emit(badgeName);
    let userlist: string[] = [];
    let theBadge;
    await this.userService.getBadges().then(res => {
      res.forEach((badge) => {
        if (badge.id === badgeId) {
          userlist = badge.users;
          theBadge = badge;
          theBadge.users = userlist;
        }
      });
    });
    await this.userService.updateBadges(theBadge, this.myData.username);
    this.displayUpdateMessage();
  }

  checkForBadge(id: string) {
    let found = false;
    this.myBadges.forEach((badge) => {
      if (badge.id === id) found = true;
    })
    return found;
  }

  findMagnets() {
    magnetList.magnets.forEach(magnet => {
      this.allMagnets.push({ magnetName: magnet.name, displayText: magnet.displayName, owned: false })
    })
  }

  async findFreeItems() {
    const fetchedData = await this.userService.getFreeItems();
    this.allFreeItems = fetchedData;

    for (const item of this.allFreeItems) {
      const specificUser = item.users.includes(this.myData.username);

      if (specificUser && specificUser != undefined) {
        this.freeItem = true;
        this.freeItemName = item.name;
      }
    }
  }

  async getUser() {
    return await this.userService.getUser(this.myData.username).then(res => {
      return res
    })
  }

  async changeFreeItems(isChecked: boolean, freeItemId: string) {
    let userList: string[] = [];
    let theFreeItem;

    await this.userService.getFreeItems().then(res => {
      res.forEach((freeItem: { id: string; users: string[]; }) => {
        if (freeItem.id === freeItemId) {
          userList = freeItem.users;
          theFreeItem = freeItem;

          if (isChecked) {
            userList.push(this.myData.username);
            theFreeItem.users = userList
            this.userService.addUserFreeItem(freeItemId, userList);
          }

          if (!isChecked) {
            const index = userList.indexOf(this.myData.username)
            userList.splice(index, 1);
            theFreeItem.users = userList
            this.userService.addUserFreeItem(freeItemId, userList);

          }
        }
      });
    });
    this.displayUpdateMessage();
  }

  magnetChangeOwned(magnetName: string) {
    this.allMagnets.forEach((magnet) => {
      if (magnetName === magnet.magnetName) {
        magnet.owned = !magnet.owned;
      }
    })

    this.updateUser();
  }

  dateFormatValidator(control: FormControl) {
    const validFormat = /^\d{4}-\d{2}-\d{2}$/;
    if (control.value && !validFormat.test(control.value)) {
      return { invalidFormat: true };
    }
    return null;
  }
}
