export const environment = {
  production: false,
  test: true,
  googleAnalytics: {
    trackingId: "G-1XT4GN4BTX"
  },
  vapidKey: "BCIESojot7kYEmapfnF4J5eSGVD-234LFa4pIP8HfZZ6_GUEJNYe1ag9vecfqytY2d4XJOq-gGqgyHVXAEBzRPU",
  serviceWorker: true,
  application: 'Angular Webapp', 
  title: 'Webapp',
  disableLogging: false,
  alertDuration: 3000,
  alertHorizontalPosition: 'right',
  alertVerticalPosition: 'top',
  userPoolId: 'eu-west-1_9Lb9Wb12F',
  userPoolWebClientId: '68hnjk3ajog38q9nsukfb79vif',
  endpoint: 'https://test.learn.trainstation.se/api',
  appVersion: require('../../package.json').version,
};
