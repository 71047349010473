import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})

export class SignInFormComponent implements OnInit {
  hide = true;
  email = new FormControl('', [Validators.required, Validators.email]);
  viewHeight: string;
  errorMsgNoMail = "";
  errorMsgIncorrectMail = "";

  constructor(private translate: TranslateService) {
    this.viewHeight = "";
  }

  ngOnInit(): void {
    this.viewHeight = window.innerHeight + "px";
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      this.translate.get('sign-in-form.email-error-nomail').subscribe((res: string) => {
        this.errorMsgNoMail = res;
      })
      return this.errorMsgNoMail;
    }

    this.translate.get('sign-in-form.email-error-incorrect').subscribe((res: string) => {
      this.errorMsgIncorrectMail = res;
    })
    return this.errorMsgIncorrectMail;
  }

  //dynamically check the screensize to fix the buttons so there is no trouble for any device
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.viewHeight = window.innerHeight + "px";
  }
}
