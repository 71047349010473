<div class="ts-why-component ts-container">
  <h2 [innerHTML]="'approach.heading' | translate"></h2>
  <div class="ts-flex ts-flex--column">
    <div class="ts-video">
      <youtube-player [width]="viewWidth" [height]="(viewWidth*.6)" #player videoId="CCJqudNALmE"
        [playerVars]="{rel: 0}"></youtube-player>
    </div>
    <div class="ts-video">
      <youtube-player [width]="viewWidth" [height]="(viewWidth*.6)" #player videoId="MsHcinespf4"
        [playerVars]="{rel: 0}"></youtube-player>
    </div>
    <div>
      <div class="ts-app-flow">
        <button mat-raised-button routerLink="/our-dream">
          <span>{{'app.nav-1' | translate}}</span>
        </button>
        <mat-icon>arrow_forward</mat-icon>
        <button mat-raised-button class="ts-btn-selected" routerLink="/our-approach">
          <span>{{'app.nav-2' | translate}}</span>
        </button>
        <mat-icon>arrow_forward</mat-icon>
        <button mat-raised-button routerLink="/our-reception">
          <span>{{'app.nav-3' | translate}}</span>
        </button>
      </div>
      <p [innerHTML]="'app.nav-text-2' | translate"></p>
    </div>

    <div class="ts-digital">
      <hr />
      <h3>{{'approach.subheading-1' | translate}}</h3>
      <div class="ts-video">
        <youtube-player [width]="viewWidth" [height]="(viewWidth*.6)" #player videoId="w2rYQ4zt5HY"
          [playerVars]="{rel: 0}"></youtube-player>
      </div>
      <p [innerHTML]="'approach.paragraph-1' | translate"></p>
      <div>
        <hr />
        <h3>{{'approach.subheading-2' | translate}}</h3>
        <div class="ts-video">
          <youtube-player [width]="viewWidth" [height]="(viewWidth*.6)" #player videoId="N2mgz54TeYM"
            [playerVars]="{rel: 0}"></youtube-player>
        </div>
        <p [innerHTML]="'approach.paragraph-1' | translate"></p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!scrollPostionIsTop">
  <button matTooltip="Gå till toppen av sidan" [matTooltipPosition]="'left'" class="ts-scroll-to-top" mat-mini-fab
    color="primary" (click)="onScrollToTop()">
    <div class="scroll-icon"><mat-icon>arrow_upward</mat-icon></div>
  </button>
</div>
