import { Component } from '@angular/core';
import { ToolbarHeightService } from '../toolbar-height.service';
import { HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.scss']
})

export class WhyComponent {
  viewWidth: number;
  viewHeight: string;
  private apiLoaded = false;
  player: any;
  scrollPostionIsTop = true;

  constructor(private toolbarHeightService: ToolbarHeightService, private readonly viewport: ViewportScroller) {
    this.viewWidth = window.innerWidth;
    this.viewHeight = "";
    this.getInnerWidthMax();
  }

  ngOnInit(): void {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";

    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  getInnerWidthMax() {
    if (window.innerWidth > 600) {
      this.viewWidth = 600;
    }
    else if (window.innerHeight < 700 && window.innerWidth < 600) {
      this.viewWidth = 450;
      if (window.innerWidth < this.viewWidth) {
        this.viewWidth = window.innerWidth;
      }
    }
    else {
      this.viewWidth = window.innerWidth
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";
    this.getInnerWidthMax();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (scrollY === 0) {
      this.scrollPostionIsTop = true;
    } else {
      this.scrollPostionIsTop = false;
    }
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }
}
