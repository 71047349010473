import { CourseCategory, CourseVideo } from './../../modules/interfaces';
import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private authService: AuthService) { }

  async getCategories() {
    const baseUrl = `${environment.endpoint}/v1`;
    const url = `/categories?allStatuses=false&limit=100`;
    let categories;
    await fetch(baseUrl + url, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((response: Response) => response.json())
      .then((response) => {
        categories = response.categories;
      })
      .catch((error) => {
        console.log(error);
      });

    return categories as unknown as CourseCategory[]
  }

  async getVideos(categoryId: string) {
    const baseUrl = `${environment.endpoint}/v1`;
    const url = `/videos/${categoryId}`;
    let videos;

    await fetch(baseUrl + url, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }),
    })
      .then((response: Response) => response.json())
      .then((response) => {
        videos = response.videos;
      })
      .catch((error) => {
        console.log(error);
      });

    return videos as unknown as CourseVideo[];
  }
}
