import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import QRCode from 'qrcode'

@Component({
  selector: 'dialog-qr',
  templateUrl: 'dialog-qr.component.html',
  styleUrls: ['./dialog-qr.component.scss']
})
export class DialogQrComponent implements OnInit {
  username: string;
  QRImage = ''

  constructor(public dialogRef: MatDialogRef<DialogQrComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DialogQrComponent>) {
    this.username = data.username;

  }
  ngOnInit(): void {
    this.loadQr();
  }

  close() {
    this.dialog.close()
  }

  loadQr() {
    QRCode.toDataURL(this.username)
    .then(url => {
      this.QRImage = url
    })
    .catch(err => {
      console.error(err)
    })
  }

}