import { Component, Input } from '@angular/core';
import { User } from 'src/modules/interfaces';

@Component({
  selector: 'app-usercards',
  templateUrl: './usercards.component.html',
  styleUrls: ['./usercards.component.scss']
})
export class UsercardsComponent {
  @Input() fromParent: User[] = [];
}
