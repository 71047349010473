export interface PolicyStatement {
    readonly Effect: 'Allow' | 'Deny'
    readonly Action: readonly string[]
    readonly Resource: readonly string[]
  }
  export type PolicyStatements = readonly PolicyStatement[]
  export interface PolicyDocument {
    readonly Version: '2012-10-17'
    readonly Statement: PolicyStatements
  }
  
  export const validLocations = [
    'bus',
    'haninge',
    'karlskoga',
    'skultuna',
    'vardagsrummet',
    'vivalla',
  ];
  