<div class="flex" [style.height]="viewHeight">
  <div class="flex box green-theme">
    <button class="box" mat-raised-button color="primary" (click)="navigateToItem('Jagär')"
      (swiperight)="navigateToItem('Jagär')">
      <div class="speech-bubble">
        <h2>{{'value-words.jagär.speech-bubble' | translate}}</h2>
      </div>
      <div class="subheading" [innerHTML]="'value-words.jagär.subheading' | translate"></div>
    </button>
  </div>
  <div class="flex box red-theme">
    <button class="box" mat-raised-button color="primary" (click)="navigateToItem('Jagvågar')"
      (swiperight)="navigateToItem('Jagvågar')">
      <div class="speech-bubble">
        <h2>{{'value-words.jagvågar.speech-bubble'| translate}}</h2>
      </div>
      <div class="subheading" [innerHTML]="'value-words.jagvågar.subheading'| translate"></div>
    </button>
  </div>
  <div class="flex box orange-theme">
    <button class="box" mat-raised-button color="primary" (click)="navigateToItem('Jagkan')"
      (swiperight)="navigateToItem('Jagkan')">
      <div class="speech-bubble">
        <h2>{{'value-words.jagkan.speech-bubble' | translate}}</h2>
      </div>
      <div class="subheading" [innerHTML]="'value-words.jagkan.subheading' | translate"></div>
    </button>
  </div>
</div>