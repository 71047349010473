import { environment } from '../../environments/environment';

export const amplifyConfiguration = {
    Auth: {
        region: 'eu-west-1',
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
        oauth: {
            scope: [ 'phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin' ],
            responseType: 'code',
        }
        },
        API: {
        endpoints: [
            {
            name: 'backend',
            endpoint: environment.endpoint,
            },
        ],
        },
    }