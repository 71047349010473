import { Injectable } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, map } from 'rxjs';
import { AuthState, CognitoUser } from 'src/modules/interfaces';

const initialAuthState = {
  isLoggedIn: false,
  username: null,
  groups: null
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );
  readonly auth$ = this._authState.asObservable();
  readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));

  constructor() { 
    this.initSession();
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'tokenRefresh':
          this.initSession();
          break;
        case 'signOut':
          window.location.reload();
          break;
        default:
          this._authState.next(initialAuthState);
          break;
      }
    });
  }

  async signOut() {
    await Auth.signOut();
  }

  private initSession() {
    Auth.currentSession().then(session => {
      const token = session.getAccessToken().getJwtToken();
      const decoded = jwt_decode(token) as CognitoUser;
      this._authState.next({ isLoggedIn: true, username: decoded.username, groups: decoded['cognito:groups'] });
    });
  }

  getGroups() {
    return this._authState.value.groups || [];
  }

  isInRole(allowedRoles: string[]): boolean {
    const userGroups = this.getGroups();

    return allowedRoles.some(allowedRole =>
      userGroups.some(userGroup => 
        userGroup.toLowerCase() === allowedRole.toLowerCase() ||
        (allowedRole.toLowerCase() === "trainer" && userGroup.toLowerCase().startsWith("trainer"))
      )
    );
  }
  
  getCurrentUser = async (): Promise<CognitoUser | null> => {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch {
      return null
    }
  };

  getAuthenticatedUserState(){
    return Auth.currentAuthenticatedUser()
    .then(()=>{return true;})
    .catch(()=>{return false;})
  }
}
