import { Router } from '@angular/router';
import { LocalService } from './../local.service';
import { ViewportScroller } from '@angular/common';
import { Component, Input, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/assets/services/user.service';
import { User } from 'src/modules/interfaces';
import { NotificationService } from '../notification.service';
import { SwPush } from '@angular/service-worker';
import { AuthService } from '../auth/auth.service';
import { PolicyDocument, validLocations } from 'common';
import { SearchComponent } from './search/search.component';
// @ts-ignore
import PBAC from 'pbac'
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  @ViewChild('searchComponent') searchComponent!: SearchComponent;
  @Input() userList: User[] = [];
  @Input() isDone = false;

  scrollPostionIsTop = true;
  location = "";
  localstorageID: string;
  oldSite = "";
  newSite = "";
  notificationsAllowed = false;
  loggedInUserGroups: string[] = [];
  locations: { text: string; value: string }[] = [
    { text: 'Vivalla', value: 'vivalla' },
    { text: 'Haninge', value: 'haninge' },
    { text: 'Bussen', value: 'bus' },
    { text: 'Karlskoga', value: 'karlskoga' },
    { text: 'Skultuna', value: 'skultuna' }
  ];
  loggedInUser: Promise<void>
  policydocument?: PolicyDocument;
  loggedInUserGroups2 = '';

  fromSearch(list: User[]) {
    this.userList = list
  }

  checkIfDone(value: boolean) {
    this.isDone = value;
  }

  reloadLocation(): void {
    this.searchComponent.reloadUsers();
    this.onLocationChange(this.location)
  }

  constructor(private readonly viewport: ViewportScroller, public localService: LocalService, public router: Router, public userService: UserService, private notificationService: NotificationService, private swPush: SwPush, public auth: AuthService) {
    this.localstorageID = "123";
    this.loggedInUser = this.auth.getCurrentUser().then((cognitoUser: any) => {
      this.policydocument = JSON.parse(cognitoUser.signInUserSession?.idToken?.payload.policydocument);
      if (this.policydocument) {
        this.getLocations(this.policydocument)
      }
    }
    )
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (scrollY === 0) {
      this.scrollPostionIsTop = true;
    } else {
      this.scrollPostionIsTop = false;
    }
  }

  getLocations = (policydocument: PolicyDocument) => {
    const pbac = new PBAC(policydocument)
    const userGroups = pbac.evaluate({ action: 'user:ListUsers', resource: 'trn:user:*' })
      ? '*'
      : validLocations.filter((location) => pbac.evaluate({ action: 'user:ListUsers', resource: `trn:user:${location}` })).join(',')

    this.localService.setUserPolicyGroups(userGroups)
    if (userGroups !== '*') {
      this.sanitizeLocation(userGroups.split(','))
    }
  }

  sanitizeLocation(userGroups: string[]) {
    const validLocations: string[] = []
    const pattern = /^SiteAdmin|^Trainer/
    userGroups.map(item => {
      const sanitized = item.replace(pattern, '').trim().toLowerCase();
      if (!validLocations.find(x => x === sanitized)) {
        validLocations.push(sanitized)
      }
    })
    this.locations = this.locations.filter(location =>
      validLocations.includes(location.value))

    if (this.locations.length === 1) {
      this.onLocationChange(this.locations[0].value)
      this.location = this.locations[0].value
    }
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  ngOnInit() {
    const savedLocation = localStorage.getItem(this.generateKey());
    this.location = savedLocation ? JSON.parse(savedLocation) : "";
    this.userService.checkNotifications();
  }

  generateKey() {
    return this.router.url;
  }

  onLocationChange(eventValue: string) {
    const oldLocation = localStorage.getItem("/our-visitors");
    this.oldSite = oldLocation ? JSON.parse(oldLocation) : "";
    this.isDone = false;
    this.newSite = eventValue;

    if (this.notificationsAllowed) {
      this.userService.unWatch(this.oldSite);
      this.userService.watch(this.newSite);
    }
    localStorage.setItem("/our-visitors", JSON.stringify(eventValue));
  }

  async toggleNotifications() {
    this.notificationsAllowed = !this.notificationsAllowed;
    const id = this.userService.checkNotifications();
    const site = localStorage.getItem("/our-visitors");
    if (this.notificationsAllowed) {
      if (site) {
        const parsedSite = JSON.parse(site);
        this.notificationService.subscribeToPushNotifications();
        this.userService.watch(parsedSite);
      }
    } else {
      if (id) {
        await this.removeNotifications(id);
        this.userService.unWatch(this.newSite);
        if (site) {
          const parsedSite = JSON.parse(site);
          this.userService.unWatch(parsedSite)
        }
      }
    }
  }

  async removeNotifications(id: string) {
    try {
      await this.userService.removeSubscription(id);
      await this.swPush.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  }
}
