import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/assets/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Badge, Materials, User } from 'src/modules/interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogChangepasswordComponent } from './dialog-changepassword/dialog-changepassword.component';
import { groupValidator, permissionValidator } from 'src/assets/services/permissionValidator';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalService } from 'src/app/local.service';
import { locations, groups } from '../../../../../../common';

@Component({
  selector: 'app-editor-card',
  templateUrl: './editor-card.component.html',
  styleUrls: ['./editor-card.component.scss'],
})

export class EditorCardComponent implements OnInit {
  showZipcodeForm = false;
  showEmailForm = false;
  nickName = "";
  email = "";
  birthdate = new Date('2000-01-01');
  zipcode = 0;
  materials: Materials[] = [];
  badges: Badge[] = [];
  userForm!: FormGroup;
  locations = locations
  groups = groups
  homelocation = "";
  selectedGroups: string[] = [];
  loggedInUserGroups: string[] = [];
  licences: Badge[] = []

  @Output() badgeEvent = new EventEmitter<any>();

  @Input() userData: User = {
    username: "",
    email: "",
    attributes: {
      nickname: "",
      gender: "",
      "custom:birthdate": new Date(2000 - 20 - 20),
      "custom:zipcode": 99999,
      homelocation: ""
    },
    groups: [],
    badges: [],
    materials: []
  };

  constructor(public localService: LocalService, public userService: UserService, public snackBar: MatSnackBar, private translate: TranslateService, private fb: FormBuilder, public dialog: MatDialog, public auth: AuthService) { }

  async ngOnInit() {
    this.populateUser()
    this.nickName = this.userData.attributes.nickname;
    this.email = this.userData.email;
    this.zipcode = this.userData.attributes["custom:zipcode"];
    this.birthdate = this.userData.attributes["custom:birthdate"];
    this.loggedInUserGroups = this.auth.getGroups()
    this.userForm = this.fb.group({
      nickname: [this.userData.attributes.nickname, Validators.required],
      birthdate: ['', [Validators.required, this.dateFormatValidator]]
    });

    this.loadBadges();
  }

  async populateUser() {
    return await this.userService.getUser(this.userData.username).then(res => {
      this.userData = res
      this.selectedGroups = this.userData.groups;
      this.homelocation = this.userData.attributes.homelocation
      this.getMaterials();
    })
  }

  radioChangeLocation(location: string): void {
    this.homelocation = location.toLocaleLowerCase()
  }

  checkboxChangeGroup(group: string) {
    this.selectedGroups = this.selectedGroups || [];

    const index = this.selectedGroups.indexOf(group);
    if (index === -1) {
      this.selectedGroups.push(group);
    } else {
      this.selectedGroups.splice(index, 1);
    }
  }

  isCheckedLocation(location: string): boolean {
    return location.toLocaleLowerCase() === this.homelocation;
  }

  isCheckedGroup(group: string): boolean {
    return this.selectedGroups ? this.selectedGroups.includes(group) : false;
  }

  isDisabled(groups: string): boolean {
    if (this.loggedInUserGroups) {
      const isSuperAdmin = groupValidator(this.loggedInUserGroups, ["SuperAdmin"]);
      const isSiteAdminAll = groupValidator(this.loggedInUserGroups, ["SiteAdminAll"]);
      const isSiteAdminLocation = permissionValidator(this.loggedInUserGroups, ["SiteAdmin"]);

      if (isSuperAdmin) {
        return false;
      }

      if (isSiteAdminAll && groups !== "SuperAdmin" && !groups.startsWith("SiteAdmin")) {
        return false;
      }

      if (isSiteAdminLocation && !groups.startsWith("Site")) {
        return this.sanitizeLocation(this.loggedInUserGroups, groups)
      }
    }
    return true
  }

  isDisabledRadio(selectedGroups: string[]) {
    const isAdmin = permissionValidator(this.loggedInUserGroups, ["SuperAdmin", "SiteAdmin"]);
    const isTrainer = permissionValidator(this.loggedInUserGroups, ["Trainer"]);

    if (isAdmin) {
      return false
    }
    if (isTrainer && selectedGroups?.length === 0) {
      return false
    }
    return true
  }

  sanitizeLocation(loggedInUserGroups: string[], group: string): boolean {
    const validLocations: string[] = []
    const pattern = /^SiteAdmin|^Trainer/
    const sanitizedGroup = group.replace(pattern, '').trim().toLowerCase();
    
    loggedInUserGroups.map(item => {
      const sanitized = item.replace(pattern, '').trim().toLowerCase();
      validLocations.push(sanitized)
    })
    if (validLocations.includes(sanitizedGroup)) {
      return false
    }
    return true
  }

  isAdmin(): boolean {
    const isAdmin = permissionValidator(this.loggedInUserGroups, ["SiteAdmin", "SuperAdmin"]);
    if (isAdmin) {
      return true;
    }
    return false
  }

  changePassword() {
    this.dialog.open(DialogChangepasswordComponent, {
      maxWidth: '475px',
      data: {
        username: this.userData.username
      }
    });
  }

  updateUser() {
    if (this.userForm.valid) {
      const isAdmin = permissionValidator(this.loggedInUserGroups, ["SuperAdmin", "SiteAdmin"]);
      const tempNewUserData: any = {
        username: this.userData.username,
        email: this.email,
        attributes: {
          nickname: this.nickName,
          gender: this.userData.attributes.gender,
          "custom:birthdate": this.birthdate,
          "custom:zipcode": this.zipcode,
          homelocation: this.homelocation,
        },
        badges: this.userData.badges,
        materials: this.userData.materials,
        ...(isAdmin ? { groups: this.selectedGroups } : {}),
      };

      Object.keys(tempNewUserData).forEach(key => tempNewUserData[key] === undefined && delete tempNewUserData[key]);
      Object.keys(tempNewUserData.attributes).forEach(key => tempNewUserData.attributes[key] === undefined && delete tempNewUserData.attributes[key]);
      
      this.userService.patchUser(this.userData.username, tempNewUserData).then(() => {
        this.displayUpdateMessage();
      }).catch(error => this.snackBar.open(error, 'Stäng', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }))
    }
  }

  displayUpdateMessage() {
    let msg = "";
    this.translate.get('profile.editor-card.update-message').subscribe((res: string) => {
      msg = res;
    })
    this.snackBar.open(msg + this.userData.username, '', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  addZipcode() {
    this.showZipcodeForm = !this.showZipcodeForm;
  }

  addEmail() {
    this.showEmailForm = !this.showEmailForm;
  }

  async loadBadges() {
    const location = JSON.parse(localStorage.getItem("/our-visitors")!);
    if (location) {
      await this.userService.getBadges(location).then(res => {
        res.map((item) => {
          if (item.type === 'machine') {
            this.licences.push(item)
          }
          if (item.type === 'badge') {
            this.badges.push(item);
          }
        })
      })
    }
  }

  checkForBadge(badgeId: string): boolean {
    return this.userData.badges?.includes(badgeId) ?? false;
  }
  
  updateBadge(isChecked: boolean, badgeId: string): void {
    if (!this.userData.badges) {
      this.userData.badges = [];
    }
  
    if (isChecked) {
      if (!this.userData.badges.includes(badgeId)) {
        this.userData.badges.push(badgeId);
      }
    } else {
      this.userData.badges = this.userData.badges.filter(id => id !== badgeId);
    }
  }

  async getMaterials() {
    this.materials = await this.userService.getFreeItems();
  }
  
  isMaterialChecked(materialId: string): boolean {
    return this.userData.materials?.includes(materialId) ?? false;
  }
  
  toggleMaterial(isChecked: boolean, materialId: string) {
    if (!this.userData.materials) {
      this.userData.materials = [];
    }
  
    if (isChecked) {
      if (!this.userData.materials.includes(materialId)) {
        this.userData.materials.push(materialId);
      }
    } else {
      this.userData.materials = this.userData.materials.filter(id => id !== materialId);
    }
  }

  async getUser() {
    return await this.userService.getUser(this.userData.username).then(res => {
      this.userData = res
      return res
    })
  }

  dateFormatValidator(control: FormControl) {
    const validFormat = /^\d{4}-\d{2}-\d{2}$/;
    if (control.value && !validFormat.test(control.value)) {
      return { invalidFormat: true };
    }
    return null;
  }
}
