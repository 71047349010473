<div class="ts-user-card">
  <div class="ts-user-card__view">

    <div class="ts-user-card-col">
      <div class="ts-meta-username">{{profileData.username}}</div>
      <div class="ts-user-card__nickname"> 
        <div class="ts-meta-nickname">{{'profile.info-card.nickname' | translate}}<b>{{profileData.attributes.nickname}}</b></div>
      </div>
      <div class="ts-user-card__birthdate">
        <div class="ts-meta-birthdate">{{'profile.info-card.birthdate' | translate}}<b>{{profileData.attributes['custom:birthdate']}}</b></div>
      </div>
      <div class="ts-user-card__zipcode">
        <div class="ts-meta-zipcode">{{'profile.info-card.zipcode' | translate}}<b>{{profileData.attributes['custom:zipcode']}}</b></div>
      </div>
    </div>

    <div class="ts-user-card-col">
      <div class="ts-meta-qr" (click)="openDialog('300ms','200ms')">
        <img class="ts-qr-image" src="../../../../../assets/img/QR-code/qr-code.svg" alt="qr-code"/>
      </div>
    </div>
  </div>

  <div class="ts-user-card-badge-row">
    <div class="ts-badge" *ngFor="let badgeName of badgeNames;">
        <img src="{{getImageForBadge(badgeName)}}" class="shake">
    </div>
  </div>
  
  <div class="ts-user-card__email">
    <div class="ts-meta-email">{{profileData.email}}</div>
  </div>

  <div class="ts-user-card__progress">
    <span class="ts-uppercase">{{'profile.info-card.week' | translate}}<div class="ts-user-progress-data" *ngIf="countWeek >= 0; else loaderBlock">{{ countWeek }}</div>
      <ng-template #loaderBlock><div class="loader-text"></div></ng-template>
    </span>
    <span class="ts-uppercase">{{'profile.info-card.total' | translate}}<div class="ts-user-progress-data" *ngIf="countTotal >= 0; else loaderBlock">{{ countTotal }}</div>
      <ng-template #loaderBlock><div class="loader-text"></div></ng-template>
    </span>
    <!-- Om vi vill att man ska kunna klicka på filerna senare -->
    <!-- <span class="ts-uppercase">Filer <div class="ts-user-progress-data ts-user-files__data" *ngIf="countUploadedFiles >= 0; else loaderBlock">{{ countUploadedFiles }}</div> -->
    <span class="ts-uppercase">{{'profile.info-card.files' | translate}}<div class="ts-user-progress-data" *ngIf="countUploadedFiles >= 0; else loaderBlock">{{ countUploadedFiles }}</div>
      <ng-template #loaderBlock><div class="loader-text"></div></ng-template>
    </span>
    <span class="ts-uppercase">{{'profile.info-card.videos' | translate}}<div class="ts-user-progress-data" *ngIf="countWatchedVideos >= 0; else loaderBlock">{{ countWatchedVideos }}</div>
      <ng-template #loaderBlock><div class="loader-text"></div></ng-template>
    </span>
  </div>
</div>
