import { LocalService } from './../../local.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnChanges } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/assets/services/user.service';
import { User } from 'src/modules/interfaces';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnChanges {
  searchString = "";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userList: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() toProfile = new EventEmitter<any[]>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: any[] = [];
  @Input() fromProfile = "";

  isDone = true;
  @Output() isProfileReady = new EventEmitter<boolean>();
  
  constructor(public userService: UserService, public localService: LocalService, private auth: AuthService){
  }

  ngOnChanges(){
    this.loadCheckedInUsers();
    this.userService.getFreeItems()
  }

  sendToParent(){
    this.toProfile.emit(this.userList);
    this.isProfileReady.emit(this.isDone);  
  }

  reloadUsers() {
    this.loadCheckedInUsers();  
  }
  loadCheckedInUsers () { 
    const today = new Date().toISOString().split('T')[0];
    const userGroups = this.localService.getUserPolicyGroups()
    if (this.fromProfile && userGroups){
        try{
          this.userService.getCheckedInUsers(this.fromProfile, userGroups, today).then(res => {
            this.users = res;
            this.search();
          });
        }
        catch(error){
          console.log(error);
        }
    
    }else{
      console.log("please select a location!");
      this.users = [];
      this.search();
    }
}

  search() {
    this.userList.splice(0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const startMatch: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const restMatch: any[] = [];
    
    if(this.searchString === ''){
      this.users.forEach(user => {
        this.userList.push(user);
      })
      this.sortUsersByName(this.userList);
    } else {
      this.users.forEach(user => {
        if(user.username.toLowerCase().startsWith(this.searchString.toLowerCase())) {
          startMatch.push(user);
        } else if(user.username.toLowerCase().includes(this.searchString.toLowerCase())) {
          restMatch.push(user);
        }
      })
      this.userList = this.sortUsersByName(startMatch).concat(this.sortUsersByName(restMatch));
    }
    this.sendToParent();
  }

  sortUsersByName(userlist: User[]) {
    return userlist.sort((a,b) => a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1);
  }

  clearSearch(){
    this.searchString = '';
    this.search();
  }

  sortRelevant(list: string[]) {
    const sortedList: string[] = [];
    const restList: string[] = [];
    list.forEach(item => {
      if(item.toLowerCase().startsWith(this.searchString.toLowerCase())) {
        sortedList.push(item);
      } else {
        restList.push(item);
      }
    });
    return sortedList.concat(restList.sort());
  }
}
