<mat-card class="editor">
  <mat-card-content>
    <fieldset>
      <ng-container *ngTemplateOutlet="badgesTemplate"></ng-container>
    </fieldset>
    <fieldset>
      <ng-container *ngTemplateOutlet="freeItemsTemplate"></ng-container>
    </fieldset>
    <fieldset>
      <ng-container *ngTemplateOutlet="magnetsTemplate"></ng-container>
    </fieldset>
    <mat-form-field appearance="fill">
      <mat-label>{{
        "profile.editor-card.editnickname.label" | translate
        }}</mat-label>
      <input matInput placeholder="{{
          'profile.editor-card.editnickname.placeholder' | translate
        }}" [(ngModel)]="nickName" />
    </mat-form-field>
    <mat-form-field [formGroup]="userForm">
      <!-- place this outside the fields that should be validated -->
      <mat-label>{{
        "profile.editor-card.editbirthyear.label" | translate
        }}</mat-label>
      <input matInput [(ngModel)]="birthdate" #birthdateInput required formControlName="birthdate" placeholder="{{
          'profile.editor-card.editbirthyear.placeholder' | translate
        }}" />
      <mat-error *ngIf="userForm.controls['birthdate'].hasError('invalidFormat')">{{
        "profile.editor-card.error.invalid-date" | translate }}</mat-error>
    </mat-form-field>
    <div *ngIf="myData.email !== undefined; then emailTemplate; else emailButton"></div>
    <div class="ts-btn-wrapper">
      <button mat-raised-button (click)="changePassword()" color="primary">
        {{ "profile.editor-card.changepassword" | translate }}
      </button>
    </div>
    <div *ngIf="
        myData.attributes['custom:zipcode'] !== undefined;
        then zipcodeTemplate;
        else zipcodeButton
      "></div>
    <fieldset>
      <ng-container *ngTemplateOutlet="homelocationTemplate"></ng-container>
    </fieldset>
    <fieldset *ngIf="isAdmin()">
      <ng-container *ngTemplateOutlet="groupsTemplate"></ng-container>
    </fieldset>
    <mat-form-field appearance="fill">
      <mat-label>{{
        "profile.editor-card.edittextfield.label" | translate
        }}</mat-label>
      <textarea matInput placeholder="{{
          'profile.editor-card.edittextfield.placeholder' | translate
        }}" [(ngModel)]="notes"></textarea>
    </mat-form-field>
    <br />
    <button mat-raised-button (click)="updateUser()" color="primary">
      {{ "profile.editor-card.updatebutton" | translate }}
    </button>
  </mat-card-content>
</mat-card>

<ng-template #badgesTemplate>
  <p class="checkbox-label">Badges</p>
  <div *ngFor="let badge of badges" class="checkboxes">
    <div *ngIf="!badge.specialBadge">
      <mat-checkbox checked="{{ checkForBadge(badge.id) }}" (change)="updateBadge(badge.id, badge.name)">{{ badge.name
        }}</mat-checkbox>
    </div>
  </div>
  <p class="checkbox-label">Maskinkörkort</p>
  <div *ngFor="let badge of badges" class="checkboxes">
    <div *ngIf="badge.specialBadge">
      <mat-checkbox checked="{{ checkForBadge(badge.id) }}" (change)="updateBadge(badge.id, badge.name)">{{ badge.name
        }}</mat-checkbox>
    </div>
  </div>
</ng-template>

<ng-template #magnetsTemplate>
  <p class="checkbox-label">Magnets</p>
  <div *ngFor="let magnet of allMagnets" class="checkboxes">
    <mat-checkbox checked="{{ magnet.owned }}" (click)="magnetChangeOwned(magnet.magnetName)">{{ magnet.displayText
      }}</mat-checkbox>
  </div>
</ng-template>

<ng-template #freeItemsTemplate>
  <p class="checkbox-label">
    {{ "profile.editor-card.editFreeItems.label" | translate }}
  </p>
  <div *ngFor="let item of allFreeItems" class="checkboxes">
    <mat-checkbox [checked]="item.users.includes(myData.username)"
      (change)="changeFreeItems($event.checked, item.id)">{{ item.name }}</mat-checkbox>
  </div>
</ng-template>

<ng-template #emailTemplate>
  <mat-form-field appearance="fill">
    <mat-label>{{
      "profile.editor-card.editemail.label" | translate
      }}</mat-label>
    <input matInput [(ngModel)]="email" placeholder="{{
        'profile.editor-card.editemail.placeholder' | translate
      }}" />
  </mat-form-field>
  <div *ngIf="showEmailForm">
    <button mat-stroked-button color="primary" class="ts-btn" (click)="addEmail()">
      {{ "profile.editor-card.hide" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #emailButton>
  <div *ngIf="!showEmailForm; else emailTemplate">
    <button mat-stroked-button color="primary" class="ts-btn" (click)="addEmail()">
      {{ "profile.editor-card.addemail.label" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #zipcodeTemplate>
  <mat-form-field appearance="fill">
    <mat-label>{{
      "profile.editor-card.editzipcode.label" | translate
      }}</mat-label>
    <input matInput placeholder="{{
        'profile.editor-card.editzipcode.placeholder' | translate
      }}" [(ngModel)]="zipcode" />
  </mat-form-field>
  <div *ngIf="showZipcodeForm">
    <button mat-stroked-button color="primary" class="ts-btn" (click)="addZipcode()">
      {{ "profile.editor-card.hide" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #zipcodeButton>
  <div *ngIf="!showZipcodeForm; else zipcodeTemplate">
    <button mat-stroked-button color="primary" class="ts-btn" (click)="addZipcode()">
      {{ "profile.editor-card.addzipcode.label" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #homelocationTemplate>
  <p class="radiobutton-label">Home Location</p>
  <div class="radiobuttons">
    <ng-container>
      <mat-radio-group>
        <mat-radio-button (change)="radioChangeLocation(location)" [disabled]="isDisabledRadio(selectedGroups)" [checked]="isCheckedLocation(location)"
          class="radiobutton" *ngFor="let location of locations" [value]="location">
          {{ location }}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>

  </div>
</ng-template>

<ng-template #groupsTemplate>
  <p class="checkbox-label">Groups</p>
  <div class="checkboxes">
    <ng-container *ngFor="let group of groups">
      <div *ngIf="!isDisabled(group)">
        <mat-checkbox (change)="checkboxChangeGroup(group)" [disabled]="isDisabled(group)"
          [checked]="isCheckedGroup(group)">
          {{ group }}
        </mat-checkbox>
      </div>
    </ng-container>
  </div>
</ng-template>