import { Router } from '@angular/router';
import { Component, HostListener } from '@angular/core';
import { CourseService } from 'src/assets/services/course.service';
import { CourseCategory } from 'src/modules/interfaces';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent {

  categories: CourseCategory[] = []; 
  isDone = false;
  scrollPostionIsTop = true;

  constructor(private courseService: CourseService, private router: Router, private readonly viewport: ViewportScroller){
    this.loadCategories();
  }

  async loadCategories(){
    this.categories = await this.courseService.getCategories();
    this.isDone = true;
  }

  navigateToCategory(categoryId: number, category: string){ 
    this.router.navigate(['/courses', categoryId, category]);
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (scrollY === 0) {
      this.scrollPostionIsTop = true;
    } else {
      this.scrollPostionIsTop = false;
    }

  }
  
}
