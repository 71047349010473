import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DurationPipe } from 'src/app/duration.pipe';
import { CourseVideo } from 'src/modules/interfaces';
import { OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-course-videos-search',
  templateUrl: './course-videos-search.component.html',
  styleUrls: ['./course-videos-search.component.scss'],
  providers: [DurationPipe]
})
export class CourseVideosSearchComponent {
  searchString = "";
  searchLevelString = "";
  searchViewsMin = 0;
  searchViewsMax = 0;
  searchLikesMin = 0;
  searchLikesMax = 0;

  maxViews = -1;
  minViews = 0;
  maxLikes = -1;
  minLikes = 0;
  
  levels: string[] = []; 

  dataLoaded = false;
  @Input() videosFromCoursePage: CourseVideo[] = [];
  @Output() toCoursePage = new EventEmitter<CourseVideo[]>();

  constructor(private durationPipe: DurationPipe){
    
  }

  ngOnChanges(){
    if(!this.dataLoaded && this.videosFromCoursePage.length > 0){
      this.videosFromCoursePage.forEach(video => {
        if(video.statistics.views > this.maxViews)  {
          this.maxViews = video.statistics.views;
        }
        if(video.statistics.views < this.minViews || this.minViews === 0)  {
          this.minViews = video.statistics.views;
        }
        if(video.statistics.likes > this.maxLikes)  {
          this.maxLikes = video.statistics.likes;
        }
        if(video.statistics.likes < this.minLikes || this.minLikes === 0)  {
          this.minLikes = video.statistics.likes;
        }
      })
      this.searchViewsMax = this.maxViews;
      this.searchLikesMax = this.maxLikes;
      this.searchViewsMin = this.minViews;
      this.searchLikesMin = this.minLikes;
      this.dataLoaded = true;
    }
  }

  search(){
    let videos = this.videosFromCoursePage;

    if(this.searchString !== "") {
      videos = this.filterTitleDescription(videos)
    }

    if(this.searchLevelString !== "") {
      videos = this.filterLevel(videos)
    }

    videos = this.filterViews(videos);
    videos = this.filterLikes(videos);
   
    this.sendToCoursePage(videos);
  }

  filterTitleDescription(videos: CourseVideo[]){
    return videos.filter(video => {
      return (video.description !== null && video.description.toLowerCase().includes(this.searchString.toLowerCase())) || 
        (video.descriptionCustom !== null && video.descriptionCustom.toLowerCase().includes(this.searchString.toLowerCase())) ||
        (video.title !== null && video.title.toLowerCase().includes(this.searchString.toLowerCase())) || 
        (video.titleCustom !== null && video.titleCustom.toLowerCase().includes(this.searchString.toLowerCase()))
    }) 
  }

  filterLevel(videos: CourseVideo[]){
    return videos.filter(video => {
      return (video.level !== null && video.level.toLowerCase().includes(this.searchLevelString.toLowerCase()))
    }) 
  }

  filterViews(videos: CourseVideo[]){
    return videos.filter(video => {
      return (video.statistics.views >= this.searchViewsMin && video.statistics.views <= this.searchViewsMax)
    })
  }

  filterLikes(videos: CourseVideo[]){
    return videos.filter(video => {
      return (video.statistics.likes >= this.searchLikesMin && video.statistics.likes <= this.searchLikesMax)
    })
  }

  clearTitleDescriptionSearch(){
    this.searchString = '';
    this.search();
  }

  clearLevelSearch(){
    this.searchLevelString = '';
    this.search();
  }

  sendToCoursePage(videos: CourseVideo[]){
    this.toCoursePage.emit(videos); 
  }
}
