<mat-card class="ts-user-card">
    <mat-card-content class="ts-user-card-content">
        <div class="ts-search-title" *ngIf="dataLoaded">Filter</div>
        <ng-container *ngIf="dataLoaded">
            <form>
                <mat-form-field (input)="search()" class="ts-search-field">
                    <mat-label>{{'courses.course-videos-search.description-search.label' | translate}}</mat-label>
                    <input matInput placeholder="{{'courses.course-videos-search.description-search.placeholder' | translate}}" name="search" [(ngModel)]="searchString" autocomplete="off">
                    <button *ngIf="searchString" (click)="clearTitleDescriptionSearch()" matSuffix mat-icon-button><mat-icon>close</mat-icon></button>
                </mat-form-field>
                <mat-form-field (input)="search()" class="ts-search-field">
                    <mat-label>{{'courses.course-videos-search.level-search.label' | translate}}</mat-label>
                    <input matInput placeholder="{{'courses.course-videos-search.level-search.placeholder' | translate}}" name="search" [(ngModel)]="searchLevelString" autocomplete="off">
                    <button *ngIf="searchLevelString" (click)="clearLevelSearch()" matSuffix mat-icon-button><mat-icon>close</mat-icon></button>
                </mat-form-field>
            </form>
            <div class="ts-slider">
                <div>
                    <label class="ts-slider-label">Views</label>
                </div>
                <div>
                    <mat-slider [min]="minViews" [max]="maxViews" discrete (change)="search()">
                        <input [(ngModel)]="searchViewsMin" matSliderStartThumb>
                        <input [(ngModel)]="searchViewsMax" matSliderEndThumb>
                    </mat-slider>
                </div>
            </div>
            <div class="ts-slider">
                <div>
                    <label class="ts-slider-label">Likes</label>
                </div>
                <div>
                    <mat-slider [min]="minLikes" [max]="maxLikes" discrete (change)="search()">
                        <input [(ngModel)]="searchLikesMin" matSliderStartThumb>
                        <input [(ngModel)]="searchLikesMax" matSliderEndThumb>
                    </mat-slider>
                </div>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>
