import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpNotification } from './notifications.interface';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/assets/services/user.service';
import { Router } from '@angular/router';

interface PushNotification {
  site: string,
  event: string,
  username: string,
  date: string
}
@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  private $http = new Subject<HttpNotification>();
  private $error = new Subject<string>();
  private $warn = new Subject<string>();
  private $info = new Subject<string>();

  public http$ = this.$http.asObservable();
  public error$ = this.$error.asObservable();
  public warn$ = this.$warn.asObservable();
  public info$ = this.$info.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private swPush: SwPush, private userService: UserService, private router: Router) { 
    this.subscribePushNotifications();
  }
  

  /**
   * Emit a HTTP error.
   * @param source Resource where error occured.
   * @param error HttpErrorResponse object.
   */
  public http(source: string, error: HttpErrorResponse): void {
    this.$http.next([source, error]);
  }

  /**
   * Emit a warning.
   * @param message Warning message.
   */
  public warn(message: string): void {
    this.$warn.next(message);
  }

  /**
   * Emit an error.
   * @param message Error message.
   */
  public error(message: string): void {
    this.$error.next(message);
  }

  /**
   * Emit information.
   * @param message Information message.
   */
  public info(message: string): void {
    this.$info.next(message);
  }

  async subscribeToPushNotifications() {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          try {
            const sub = await this.swPush.requestSubscription({
              serverPublicKey: environment.vapidKey
            });
            await this.userService.addSubscription(sub);
          }
          catch(err) {
            console.error('Could not subscribe' + err);
          }
        }
      });
    }
  }
  
  subscribePushNotifications() {
    this.swPush.messages.subscribe((message) => {
      const pushMessage = message as PushNotification;
      if (pushMessage) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(
            pushMessage.username + " har checkat in på " + pushMessage.site,
            {
              body: "Visa mer",
              vibrate: [200, 100, 200, 100, 200, 100, 200],
              icon: '../../assets/icons/icon-144x144.png',
              actions: [
                {
                  action: JSON.stringify(message),
                  title: '',
                },
              ],
            }
          );
        });
      }
    });
  
    this.swPush.notificationClicks.subscribe(() => {
      this.router.navigateByUrl("/our-visitors");
    });
  }    
  
  
}