import { ToolbarHeightService } from '../toolbar-height.service';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-visitor-progress',
  templateUrl: './visitor-progress.component.html',
  styleUrls: ['./visitor-progress.component.scss']
})
export class VisitorProgressComponent implements OnInit{
  category: string;
  viewHeight: string;
  currentApplicationVersion = environment.appVersion

  constructor(private router: Router, private toolbarHeightService: ToolbarHeightService){
    this.category = "";
    this.viewHeight = "";
  }

  ngOnInit(): void {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";
  }

  navigateToVideos(){
    this.router.navigate(['/videonav']);
  }

  navigateToItem(category: string){
    this.category = category;
    this.router.navigate(['/video-visitor-progress', category, 0]);
  }

  //dynamically check the screensize to fix the buttons so there is no trouble for any device
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";

  }

}
