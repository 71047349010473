import { LocalService } from '../local.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, ViewChild} from '@angular/core';
import { ToolbarHeightService } from '../toolbar-height.service';
import videoData from "src/app/videos.json";
import { Video } from 'src/modules/interfaces';

@Component({
  selector: 'app-video-visitor-progress',
  templateUrl: './video-visitor-progress.component.html',
  styleUrls: ['./video-visitor-progress.component.scss']
})
export class VideoVisitorProgressComponent {
  cardId: number;
  routeCategory: string;
  listOfVideos: Video[] = []; //Youtube video ids... this could be made into an xml or json file for better simpler storage.
  listOfThemes: string[];
  currentRoute: string;
  viewWidth: number;
  viewHeight: string;
  listOfColors: string[];
  isVisible: boolean;
  private apiLoaded = false;
  player: any;
  @ViewChild('myCheckbox') myCheckbox: any;


  routeIndex: number;

  constructor(private router: Router, private route: ActivatedRoute, private toolbarHeightService: ToolbarHeightService, private localStore: LocalService) {
    this.routeCategory = "";
    this.routeIndex = 0;
    this.cardId = -1;
    this.currentRoute = this.route.toString();
    this.listOfThemes = ['green-theme', 'red-theme', 'orange-theme'];
    this.viewWidth = window.innerWidth;
    this.viewHeight ="";
    this.listOfColors=['green','red','orange'];
    this.isVisible = true;



    //gets the current route to be able to use.
    this.route.params.subscribe((params) => {
      this.routeCategory = params['category'];
      this.cardId = +params['id'];
    });

    this.loadVideosOnIndex();
    this.getInnerWidthMax();
  }


    getInnerWidthMax() {
      if (window.innerWidth > 600){
        this.viewWidth = 600;
      }
      else if(window.innerHeight < 700 && window.innerWidth < 600){
          this.viewWidth = 450;
          if(window.innerWidth < this.viewWidth)
          {
            this.viewWidth = window.innerWidth;
          }
      }
      else{
        this.viewWidth =window.innerWidth
      }
    }


  loadVideosOnIndex(){
    if(this.routeCategory === 'newcomer'){
      this.routeIndex = 0;
      videoData.forEach(category => {
        category['newcomer']?.forEach(video => {
          this.listOfVideos.push(video);
        }
        );
      });
    }
    if(this.routeCategory === 'recurring'){
      this.routeIndex = 1;
      videoData.forEach(category => {
        category['recurring']?.forEach(video => {
          this.listOfVideos.push(video);
        }
        );
      });
    }
    if(this.routeCategory === 'regular'){
      this.routeIndex = 2;
      videoData.forEach(category => {
        category['regular']?.forEach(video => {
          this.listOfVideos.push(video);
        }
        );
      });
    }
  }

  //updates index and id when changes are made.
  updateIndex(newIndex: number) {
    this.router.navigate(['/video-visitor-progress/' + this.routeCategory, newIndex]);
  }

  nextCategory(){
    if (this.routeCategory == 'newcomer') {
      this.router.navigateByUrl('/our-reception', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/video-visitor-progress', 'recurring', 0]);
      }); 
    }
    else if (this.routeCategory == 'recurring') {
      this.router.navigateByUrl('/our-reception', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/video-visitor-progress', 'regular', 0]);
      }); 
    }
    else if (this.routeCategory == 'regular') {
      this.router.navigate(['/our-reception']);
    }
    
  }

 //puts the next video in line, if its the last, then it puts the next category
 nextVideo() {
  if (this.cardId === this.listOfVideos.length) {
      this.nextCategory();
  } else {
    this.cardId++;
    this.updateIndex(this.cardId);
    if (this.cardId === this.listOfVideos.length) {
      this.nextCategory();
  }
    this.checkViewed();
  }
}
  //puts the previus or last video in a list of URLs.
  previousVideo() {
    if (this.cardId === 0) {
      this.router.navigate(['/our-reception']);
    } else {
      this.isVisible = true;
      this.cardId--;
      this.updateIndex(this.cardId);
      this.checkViewed();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";
    this.getInnerWidthMax();
  }

  ngOnInit(): void {
    this.viewHeight = window.innerHeight - this.toolbarHeightService.getToolbarHeight() + "px";

    if(!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  onStateChange(event:any) {
    if (event.data === 0) {
      this.myCheckbox.checked = true;
      this.localStore.saveData(this.generateKey(), '1');
    }
  }

  onPlayerReady(event:any) {
    this.checkViewed();
  }

  checkViewed() {
    if(this.localStore.getData(this.generateKey()) === '1') {
      this.myCheckbox.checked = true;
    }
  }

  saveVideoStatus() {
    if (this.myCheckbox.checked === true) {
      this.localStore.saveData(this.generateKey(), '1');
    }
    else this.localStore.saveData(this.generateKey(), '0');
  }

  //Unique key for videos in local storage
  generateKey(){
    return this.routeCategory + this.cardId;
  }

}



