import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class LocalService {



  private readonly $policyGroups = new BehaviorSubject<any>('')
  public policyGroups$ = this.$policyGroups.asObservable()

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  public saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getData(key: string){
    return localStorage.getItem(key);
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  setUserPolicyGroups(val: string): void {
    this.$policyGroups.next(val)
  }

  getUserPolicyGroups(): string{
    return this.$policyGroups.getValue()
  }
}
