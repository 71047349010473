<div class="ts-container ts-flex--column">
  <div class="ts-center">
    <img ngSrc="../assets/img/collage.png"
      width="1446"
      height="1380"
      priority
      />
    <div class="ts-content">
      <p>{{'dream.subheading' | translate}}</p>
      <p class="ts-dream" [innerHTML]="'dream.heading' | translate"></p>
      <p [innerHTML]="'dream.paragraph' | translate"></p>
      <div class="ts-app-flow">
        <button mat-raised-button class="ts-btn-selected" routerLink="/our-dream">
          <span>{{'app.nav-1' | translate}}</span>
        </button>
        <mat-icon>arrow_forward</mat-icon>
        <button mat-raised-button routerLink="/our-approach">
          <span>{{'app.nav-2' | translate}}</span>
        </button>
        <mat-icon>arrow_forward</mat-icon>
        <button mat-raised-button routerLink="/our-reception">
          <span>{{'app.nav-3' | translate}}</span>
        </button>
      </div>
      <p [innerHTML]="'app.nav-text-1' | translate"></p>
    </div>
  </div>
</div>
